import { render, staticRenderFns } from "./TestsNew.vue?vue&type=template&id=29cc13be&scoped=true&"
import script from "./TestsNew.vue?vue&type=script&lang=js&"
export * from "./TestsNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29cc13be",
  null
  
)

export default component.exports