<template>
  <div class="view">
    <div class="content">
      <logo-banner/>
      <b-container>
        <h2>Test Hinzufügen</h2>

        <b-button variant="success" class="btn-lg" block @click="$router.push({name: 'Scan', params: {type:'test'}})">Test Scannen</b-button>
        <b-button variant="success" class="btn-lg" block to="/tests/new/manuel"> Manuell Eingeben</b-button>

        <b-button variant="danger" block @click="$router.push('/tests/')">Abbrechen</b-button>

      </b-container>
    </div>

    <menu-bar active-button="tests"></menu-bar>
  </div>
</template>

<script>
import LogoBanner from "../components/LogoBanner";
import MenuBar from "../components/MenuBar";

export default {
  name: "TestsNew",
  components: {
    LogoBanner,
    MenuBar,
  }
}
</script>

<style scoped>

</style>
